
import { NgModule, ModuleWithProviders } from '@angular/core';
import { BuilderGroupPipe } from './_pipes/builder-group.pipe';

@NgModule({
    declarations: [
        BuilderGroupPipe,
    ],
    exports: [
        BuilderGroupPipe,
    ]
})
export class SharingModule {
    static forRoot(): ModuleWithProviders {
        return {
            ngModule: SharingModule,
            providers: []
        };
    }
}
